import { gql, useEnhancedMutation } from "@uplift-ltd/apollo";
import { SpaceBetween } from "@uplift-ltd/space-between";
import { makeUrl } from "@uplift-ltd/strings";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  EnhancedFormik,
  Form,
  FormStatus,
  InputGroupField,
  SubmitButton,
} from "components/common/Form";
import Page, { PageTitle } from "components/common/Page";
import { EMAIL_PLACEHOLDER, LS_KEY_HAS_LOGGED_IN, NEW_SITE_AUTH_MESSAGE } from "constants/site";
import { MY_COURSES_URL, REGISTER_URL, RESET_PASSWORD_URL } from "constants/urls";
import { goToSafeUrl } from "helpers/urls";
import useRouterNavigation from "hooks/useRouterNavigation";
import { useRouterQuery } from "hooks/useRouterQuery";
import { CURRENT_USER_QUERY } from "hooks/useUserContext";
import {
  CheckNewlyMigratedUserMutation,
  CheckNewlyMigratedUserVariables,
  LoginUserMutation,
  LoginUserVariables,
} from "./__generated__/Login";
import Alert from "./common/Alert/index";
import { ButtonLink } from "./common/Button";
import Container from "./common/Container";

const LOGIN_USER = gql`
  mutation LoginUser($username: String!, $password: String!) {
    loginWithCookies(
      input: { clientMutationId: "LoginUser", login: $username, password: $password }
    ) {
      status
    }
  }
`;

const CHECK_IF_NEWLY_MIGRATED = gql`
  mutation CheckNewlyMigratedUser($email: String!) {
    checkNewlyMigratedUser(input: { email: $email }) {
      success
      isNewlyMigrated
    }
  }
`;

const INITIAL_VALUES = {
  username: "",
  password: "",
};

interface FormValues {
  username: string;
  password: string;
}

const validationSchema = yup.object().shape({
  username: yup.string().email().required().label("Email"),
  password: yup.string().required().label("Password"),
});

export type OnSuccessHandler = (setFormSuccess: (msg: string) => void, page: string) => void;

const LoginPage = () => {
  const {
    routerQuery: { nextPage = MY_COURSES_URL, queryEmail = "" },
  } = useRouterQuery<{ nextPage?: string; queryEmail?: string }>();
  const [email, setEmail] = useState(queryEmail);
  const [isRedirecting, setRedirecting] = useState(false);
  const { push } = useRouterNavigation();
  const [loginUser] = useEnhancedMutation<LoginUserMutation, LoginUserVariables>(
    LOGIN_USER,
    { refetchQueries: [{ query: CURRENT_USER_QUERY }] },
    { auth: false }
  );

  const [performMigratedCheck, { loading }] = useEnhancedMutation<
    CheckNewlyMigratedUserMutation,
    CheckNewlyMigratedUserVariables
  >(CHECK_IF_NEWLY_MIGRATED, {}, { auth: false });

  useEffect(() => {
    if (email) {
      performMigratedCheck({
        variables: { email },
      }).then(resp => {
        const data = resp.data?.checkNewlyMigratedUser;
        if (data?.success && data.isNewlyMigrated) {
          push(makeUrl(RESET_PASSWORD_URL, undefined, { email }));
          setRedirecting(true);
        }
      });
    }
  }, [email, performMigratedCheck, push]);

  const [isNewUser, setIsNewUser] = useState(true);

  useEffect(() => {
    setIsNewUser(localStorage.getItem(LS_KEY_HAS_LOGGED_IN) !== "1");
  }, []);

  return (
    <Container size="small">
      <Page>
        <EnhancedFormik<FormValues>
          initialValues={{ ...INITIAL_VALUES, username: email }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setFormSuccess, setFormError }) => {
            setFormError(null);

            const result = await loginUser({ variables: values });

            if (result.data?.loginWithCookies) {
              const { status } = result.data.loginWithCookies;
              if (status === "SUCCESS") {
                setFormSuccess("Logging you in…");
                localStorage.setItem(LS_KEY_HAS_LOGGED_IN, "1");
                goToSafeUrl(nextPage, MY_COURSES_URL);
              } else {
                setFormError("Oops. Something went wrong. Please try again later.");
              }
            } else {
              setFormError("Incorrect password.");
            }
          }}
        >
          <Form>
            <SpaceBetween className="my-8">
              {isNewUser && (
                <div className="text-center">
                  <PageTitle>Existing Zur Customers</PageTitle>
                  <Alert className="mb-8 text-left" theme="info">
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: NEW_SITE_AUTH_MESSAGE }} />
                    <div className="text-center mt-8">
                      <ButtonLink theme="textButton" href={RESET_PASSWORD_URL}>
                        Reset Password & Verify Account
                      </ButtonLink>
                    </div>
                  </Alert>
                </div>
              )}
              <PageTitle additionalClasses="text-center">Log In To My Account</PageTitle>
              {isNewUser && (
                <p>Already reset your password and verified your information? Log in here.</p>
              )}
              <FormStatus
                errorCodeMap={{
                  invalid_email: "Email address did not match. Please try again.",
                  incorrect_password: "Email and password did not match. Please try again.",
                }}
              />
              <InputGroupField
                name="username"
                label="Email Address"
                placeholder={EMAIL_PLACEHOLDER}
                onBlur={e => setEmail(e.target.value)}
                required
              />
              {isRedirecting ? (
                <p>Welcome! Taking you to password reset…</p>
              ) : (
                <InputGroupField name="password" label="Password" type="password" required />
              )}
              <div className="flex flex-col items-center">
                <SubmitButton disabled={loading}>Log In</SubmitButton>
                <ButtonLink theme="textButton" href={RESET_PASSWORD_URL}>
                  I forgot my password
                </ButtonLink>
              </div>
              <div className="text-center">
                <PageTitle>New Customers</PageTitle>
                <p>
                  Ready to purchase a course?
                  <br />
                  Create an account and begin your learning journey.
                </p>
                <ButtonLink
                  theme="secondary"
                  href={nextPage ? makeUrl(REGISTER_URL, null, { nextPage }) : REGISTER_URL}
                  className="mt-4"
                >
                  Create a Free Account
                </ButtonLink>
              </div>
            </SpaceBetween>
          </Form>
        </EnhancedFormik>
      </Page>
    </Container>
  );
};

export default LoginPage;
